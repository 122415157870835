<template>
  <v-dialog ref="dialog"
            :title="$t('page_admin_volunteers.admin_operation_modal.title', {fullName})"
            @closed="resetForm"
  >
    <template #body>
      <el-form label-position="top"
               v-loading="isLoading"
               size="medium"
               ref="form"
               :rules="formRules"
               :model="form"
      >
        <el-form-item prop="operationTitle"
                      :label="$t('page_admin_volunteers.admin_operation_modal.fields.operation_title')"
        >
          <el-input type="text"
                    v-model="form.operationTitle"
                    :maxlength="100"
          />
        </el-form-item>
        <el-form-item prop="amount"
                      :label="$t('page_admin_volunteers.admin_operation_modal.fields.operation_amount')"
        >
          <el-input type="number" v-model.number="form.amount" />
        </el-form-item>
      </el-form>
    </template>
    <template #actions="{close, confirm}">
      <v-button @click="close">
        {{ $t('page_admin_volunteers.admin_operation_modal.btn_cancel') }}
      </v-button>
      <v-button type="primary" @click="saveOperation(confirm)">
        {{ $t('page_admin_volunteers.admin_operation_modal.btn_confirm') }}
      </v-button>
    </template>
  </v-dialog>
</template>

<script>
import VDialog from '../../../../components/ui/v-dialog/VDialog'
import VButton from '../../../../components/ui/v-button/VButton'
import {commitAdminOperation} from '../../services/volunteer-service'

export default {
  name: 'AdminOperationModal',
  components: {VButton, VDialog},
  data() {
    return {
      fullName: '',
      volunteerID: null,
      isLoading: false,
      form: {
        operationTitle: '',
        amount: 0,
      },
      formRules: {
        operationTitle: [
          {required: true, message: this.$t('validators.field_required'),}
        ],
        amount: [
          {required: true, message: this.$t('validators.field_required'),},
          {
            validator: this.zeroValueValidate,
            trigger: 'change',
          }
        ]
      }
    }
  },
  methods: {
    zeroValueValidate(_, value, callback ) {
      if (value === 0) {
        callback(new Error(this.$t(`validators.number_value_cant_be_zero`)))
        return
      }
      callback()
    },
    open({volunteerID, fullName}) {
      this.fullName = fullName
      this.volunteerID = volunteerID
      this.$refs.dialog.openDialog()
    },
    resetForm() {
      this.form.operationTitle = ''
      this.form.amount = 0
      this.$refs.form.clearValidate()
    },
    saveOperation(confirm) {
      this.$refs.form.validate((valid) => {
        if (!valid) return
        this.isLoading = true
        const payload = {
          ...this.form,
          volunteerID: this.volunteerID
        }
        commitAdminOperation(payload)
          .then(() => {
            this.$message({
              message: `${this.$t('page_admin_volunteers.admin_operation_modal.messages.success')}`,
              type: 'success'
            })
            this.$emit('operation-commit')
            confirm()
          })
          .catch((e) => {
            this.$message({
              message: `${this.$t('alerts.common_error', e.response.status)}`,
              type: 'error'
            })
          })
          .finally(() => {
            this.isLoading = false
          })
      })
    }
  }
}
</script>

<style scoped>

</style>
