var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.isLoading),expression:"isLoading"}],staticClass:"app-container",attrs:{"element-loading-background":"rgba(255, 255, 255, 1)"}},[_c('el-card',[_c('h2',{staticClass:"heading page-heading"},[_vm._v(_vm._s(_vm.$t('page_admin_volunteers.title')))]),_c('el-table',{staticStyle:{"width":"100%"},attrs:{"data":_vm.volunteerListToShow},on:{"sort-change":_vm.customSort}},[_c('el-table-column',{attrs:{"width":150,"prop":"name","sortable":"custom","label":_vm.$t('page_admin_volunteers.table_headers.name')}}),_c('el-table-column',{attrs:{"width":150,"prop":"surname","sortable":"custom","label":_vm.$t('page_admin_volunteers.table_headers.surname')}}),_c('el-table-column',{attrs:{"width":"200","prop":"email","sortable":"custom","label":_vm.$t('page_admin_volunteers.table_headers.email')}}),_c('el-table-column',{attrs:{"width":80,"prop":"referenceCode","sortable":"custom","label":_vm.$t('page_admin_volunteers.table_headers.reference_code')}}),_c('el-table-column',{attrs:{"width":150,"prop":"totalAmountCount","sortable":"custom","label":_vm.$t('page_admin_volunteers.table_headers.donations_raised')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm.formatMoneyContent(row.totalAmountCount))+" ")])]}}])}),_c('el-table-column',{attrs:{"width":145,"prop":"totalCharges","sortable":"custom","label":_vm.$t('page_admin_volunteers.table_headers.total_charges')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm.formatMoneyContent(row.totalCharges))+" ")])]}}])}),_c('el-table-column',{attrs:{"width":165,"prop":"totalAdminOperations","sortable":"custom","label":_vm.$t('page_admin_volunteers.table_headers.total_admin_operations')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm.formatMoneyContent(row.totalAdminOperations))+" ")])]}}])}),_c('el-table-column',{attrs:{"width":155,"prop":"currentBalance","sortable":"custom","label":_vm.$t('page_admin_volunteers.table_headers.current_balance')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm.formatMoneyContent(row.currentBalance))+" ")])]}}])}),_c('el-table-column',{attrs:{"fixed":"right"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-dropdown',{attrs:{"placement":"bottom"},on:{"command":_vm.handleCommand}},[_c('span',{staticClass:"el-dropdown-link"},[_c('v-icon',{attrs:{"name":"more_horiz"}})],1),_c('el-dropdown-menu',{attrs:{"slot":"dropdown"},slot:"dropdown"},[_c('el-dropdown-item',{attrs:{"command":{
                commandName: _vm.$options.ADMIN_OPERATION_COMMAND,
                params: {
                  fullName: row.fullName,
                  volunteerID: row.volunteerID
                }
              }}},[_vm._v(" "+_vm._s(_vm.$t('page_admin_volunteers.actions.admin_operation'))+" ")])],1)],1)]}}])})],1),_c('v-pagination',{attrs:{"items":_vm.volunteerList},on:{"update:items-to-show":function($event){_vm.volunteerListToShow = $event}}}),_c('AdminOperationModal',{ref:"adminOperationModal",on:{"operation-commit":_vm.fetchVolunteerList}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }