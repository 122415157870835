<template>
  <div class="app-container"
       v-loading="isLoading"
       element-loading-background="rgba(255, 255, 255, 1)"
  >
    <el-card>
      <h2 class="heading page-heading">{{ $t('page_admin_volunteers.title') }}</h2>
      <el-table
          @sort-change="customSort"
          :data="volunteerListToShow"
          style="width: 100%"
      >
        <el-table-column
            :width="150"
            prop="name"
            sortable="custom"
            :label="$t('page_admin_volunteers.table_headers.name')"
        />
        <el-table-column
            :width="150"
            prop="surname"
            sortable="custom"
            :label="$t('page_admin_volunteers.table_headers.surname')"
        />
        <el-table-column
            width="200"
            prop="email"
            sortable="custom"
            :label="$t('page_admin_volunteers.table_headers.email')"
        />
       <el-table-column
            :width="80"
            prop="referenceCode"
            sortable="custom"
            :label="$t('page_admin_volunteers.table_headers.reference_code')"
        />
        <el-table-column
            :width="150"
            prop="totalAmountCount"
            sortable="custom"
            :label="$t('page_admin_volunteers.table_headers.donations_raised')"
        >
          <template #default="{row}">
            <div class="text-right">
              {{ formatMoneyContent(row.totalAmountCount) }}
            </div>
          </template>
        </el-table-column>
       <el-table-column
            :width="145"
            prop="totalCharges"
            sortable="custom"
            :label="$t('page_admin_volunteers.table_headers.total_charges')"
       >
         <template #default="{row}">
           <div class="text-right">
             {{ formatMoneyContent(row.totalCharges) }}
           </div>
         </template>
       </el-table-column>
        <el-table-column
            :width="165"
            prop="totalAdminOperations"
            sortable="custom"
            :label="$t('page_admin_volunteers.table_headers.total_admin_operations')"
        >
          <template #default="{row}">
            <div class="text-right">
              {{ formatMoneyContent(row.totalAdminOperations) }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
            :width="155"
            prop="currentBalance"
            sortable="custom"
            :label="$t('page_admin_volunteers.table_headers.current_balance')"
        >
          <template #default="{row}">
            <div class="text-right">
              {{ formatMoneyContent(row.currentBalance) }}
            </div>
          </template>
        </el-table-column>
        <el-table-column fixed="right">
          <template #default="{row}">
            <el-dropdown placement="bottom" @command="handleCommand">
              <span class="el-dropdown-link">
                <v-icon name="more_horiz"></v-icon>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item :command="{
                  commandName: $options.ADMIN_OPERATION_COMMAND,
                  params: {
                    fullName: row.fullName,
                    volunteerID: row.volunteerID
                  }
                }">
                  {{ $t('page_admin_volunteers.actions.admin_operation') }}
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </template>
        </el-table-column>
      </el-table>
      <v-pagination :items="volunteerList" @update:items-to-show="volunteerListToShow = $event" />
      <AdminOperationModal @operation-commit="fetchVolunteerList" ref="adminOperationModal" />
    </el-card>
  </div>
</template>

<script>
import {ROLE_VOLUNTEERS_ADMINISTRATION} from '../../../shared/constants/app-permissions'
import VPagination from '../../../components/ui/v-pagination/VPagination'
import VIcon from '../../../components/ui/v-icon/VIcon'
import {tableSort} from '../../../shared/utils/tableSort'
import AdminOperationModal from '../components/admin-operation-modal/AdminOperationModal'

const ADMIN_OPERATION_COMMAND = 'openAdminOperationModal'
import {fetchVolunteerList} from '../services/volunteer-service'
import {moneyFormat} from '../../../shared/utils/moneyFormat'

export default {
  name: 'VolunteerList',
  components: {VIcon, AdminOperationModal, VPagination},
  ROLE_VOLUNTEERS_ADMINISTRATION,
  ADMIN_OPERATION_COMMAND,
  data() {
    return {
      volunteerList: [],
      volunteerListToShow: [],
      isLoading: false
    }
  },
  created() {
    this.fetchVolunteerList()
  },
  methods: {
    customSort(sortInfo) {
      this.volunteerList = tableSort(this.volunteerList, sortInfo)
    },
    formatMoneyContent(val) {
      return moneyFormat(val)
    },
    handleCommand({commandName, params}) {
      this[commandName](params)
    },
    [ADMIN_OPERATION_COMMAND](params) {
      const { volunteerID, fullName } = params
      this.$refs.adminOperationModal.open({ volunteerID, fullName })
    },
    fetchVolunteerList() {
      this.isLoading = true
      fetchVolunteerList()
        .then(res => {
          this.volunteerList = res
        })
        .catch(() => {
          this.$message({
            message: `${this.$t('fetch error')}`,
            type: 'error'
          })
        })
        .finally(() => {
          this.isLoading = false
        })
    }
  }
}
</script>
