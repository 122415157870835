import {api} from '../../../shared/services/backend-api'

const RESOURCE = 'volunteers'

const volunteerListModel =
  ({
     adminOperationsCount: totalAdminOperations,
     paymentOperationsCount: totalCharges,
     totalOperationsCount: currentBalance,
     totalAmountCount,
     name,
     surname,
     email,
     referenceCode,
     volunteerID
   }) => ({
    totalAdminOperations,
    totalAmountCount,
    totalCharges,
    currentBalance,
    name,
    surname,
    fullName: `${name} ${surname}`,
    email,
    referenceCode,
    volunteerID
  })



const fetchVolunteerList = async () => {
  const {data} = await api.get(`${RESOURCE}`)
  
  const volunteerList = data.data.map(volunteerListModel)
  
  return volunteerList
}

const commitAdminOperation = (operation) => {
  const {
    volunteerID,
    amount: price,
    operationTitle: title
  } = operation
  
  const payload = {
    price,
    title
  }
  
  return api.post(`${RESOURCE}/${volunteerID}/balance-operations`, payload)
}

export {
  fetchVolunteerList,
  commitAdminOperation
}
